import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "First-Party Special Needs Trust",
      content: (
        <>
          <p>
            This type of special needs trust is funded by property owned by the
            beneficiary or to which the beneficiary is legally entitled (such as
            assets from a court judgment or settlement).
          </p>
        </>
      ),
    },
    {
      title: "Pooled Special Needs Trust",
      content: (
        <>
          <p>
            This type of special needs trust is established and maintained by a
            non-profit organization. The assets of many beneficiaries are
            combined or “pooled” and invested together.
          </p>
        </>
      ),
    },
    {
      title: "Third-Party Special Needs Trust",
      content: (
        <>
          <p>
            This type of special needs trust is funded by property owned by
            someone other than the beneficiary, such as property owned by family
            members.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Special Needs Trust Lawyer | Brierton, Jones & Jones"
        description="Need a top special needs trust lawyer in San Diego? Brierton, Jones & Jones, LLP will connect you with an experienced local attorney. Call us today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pt-12 pb-6 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  SECURE THEIR FUTURE
                </div>
                <h1>San Diego Special Needs Trust Lawyers</h1>
                <p>
                  If you are the parent of a child with a disability, careful
                  estate planning is critical to ensure they will be taken care
                  of after you pass away. A special needs trust is a critical
                  component of that plan.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/6.0 Special Needs Trust/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>What Is a Special Needs Trust?</h2>
                <p>
                  A special needs trust is similar to other types of trusts—it
                  sets aside assets for a beneficiary and puts them in the
                  control of a trustee. It also allows a disabled adult to
                  continue receiving government benefits (such as SSI and
                  Medi-Cal), even if the value of the assets in the trust would
                  otherwise disqualify them.
                </p>
              </div>

              <div className="mb-12 md:mb-18">
                <h2>When Is a Special Needs Trust the Right Choice?</h2>
                <p>
                  Special needs trusts are ideally suited for disabled
                  individuals, as they are designed to ensure that such
                  individuals continue to have access to the ongoing resources
                  they need and are also able to benefit from the additional
                  financial support that will enhance their quality of life.
                </p>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>
                  If you are considering consulting with a special needs trust
                  lawyer to discuss your situation, the following are some of
                  the common terms to familiarize yourself with:
                </p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={5} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="If you think this type of trust might be right for your family, our San Diego special needs trust lawyers can guide you and answer all your questions. Contact us today." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Facebook/06 Special Needs Trust.jpg"
      }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Twitter/06 Special Needs Trust.jpg"
      }
    ) {
      publicURL
    }
  }
`;

export default Page;
